.profile-container {
  padding: 95px 0 60px;
  @include breakpoint(small down) {
    padding: 0 0 60px;
  }
}

.profile-nav {
    text-transform: uppercase;
    font-size: 14px;
    li {
      margin-bottom: 4px;
      a, form.logout {
        @include transition-basic;
        display: block;
        text-transform: uppercase;
        font-size: 14px;
        margin-bottom: 0;
        background-color: $white;
        color: $black;
        letter-spacing: .1em;
        padding: 8px;
          
        &:hover, &.active {
          background-color: $black;
          color: $white;
          margin-top: 0;
          padding: 8px;

          input {
            color: $white;
          }
        }
      }

      form.logout input {
        padding: 0
      }
    }
}
  
.profile-title {
  @include inter-medium;
  font-size: 30px;
  @include mb-16;
  @include breakpoint(small down) {
    font-size: 24px;
    padding-bottom: 0;
  }
}

.profile-separator {
  border: none;
  border-top: 1px solid $grey-2;
  margin-top: 40px;
}

.responsive-table {
  overflow-x: auto;
}


.order-row {
  @include transition-basic;
  text-align: center;
  cursor: pointer;
    &:hover,
    &.selected {
      background-color: $white;
    }

    td {
      border-bottom: 1px solid $light-grey;
      border-top: 1px solid $light-grey;
      padding: 8px;
      text-align: center;
      font-size: 14px;
    }
}


.order-row-indicator-container {
  width:30px;
  position: relative;
}

.order-controls-row {
  margin-bottom: 10px;
}

.order-row-indicator {
  @include transition-basic;
  width: 8px;
  position: absolute;
  top: 13px;
  left: 4px;

    &.fa-rotate-90 {
      transform: rotate(90deg);
    }
}

.order-controls {
  display: none;
  border-left: 1px solid $gold;

    > td {
      padding: 20px 20px;
      
    }

    h4 {
      @include inter-bold;
      margin-bottom: 15px;
      font-size: 14px;
      text-transform: uppercase;
      text-align: left;
    }
}

.dispatch-data-row {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin: 2px 0;

    > div {
      flex: 1;
      font-size: 14px;
      letter-spacing: 0.05em;
    }
}

.order-controls-return {
  border-collapse: collapse;

    td {
      border-top: 1px solid $grey-2;
      padding: 8px;
      vertical-align: middle;
      font-size: 14px;
      line-height: 24px;
    }

    .product-sku {
      font-size: 12px;
      letter-spacing: 0.1em;
      margin-top: 5px;
    }
}

.new-return-request form {
  margin: 40px 0;
}

.new-return-request {
  display: none;
}

.returned-goods-row {
  display: flex;
  justify-content: space-between;
  align-items: baseline;

    div {
      font-size: 14px;
      letter-spacing: 0.05em;
    }
}

.returned-goods-item {
  border-bottom: 1px solid $grey-2;
  padding: 15px 0;
}

.cancel-return-request-form {
  @include breakpoint(small down) {
    margin-top: 20px;
  }
}
.myorders{
  border-bottom: 1px solid $grey-3;
  padding-block-end: 10px;
  display: flex;
  align-items: center;
}

.dl-horizontal {

  dt {
      width: 50%;
      float: left;
      clear: left;
      text-align:left;
      @include inter-medium;
  }

  dd {
      width: 50%;
      float: left;
      margin: 0;
      text-align: right;
  }
}

.orders-table {
  width: 100%;
  border-collapse: collapse;

  .product-sku {
    font-size: 12px;
    margin-top: 5px;
  }
  
  td {
      border-bottom: 1px solid $grey-3;
      padding: .5rem .625rem .625rem 0;
      font-weight: 400;
      text-align: left;

      &.form-request-14{
        border: 1px solid blue;
      }
  }
  img{
    width: 70px;
    @include breakpoint(small down) { 
      width: 40px;
    }
  }
}

.form-return {
  padding: 8px;
}

.button-label{
  font-size: 14px;
  text-transform: uppercase;
}

.table-orders{
  border-collapse: collapse;
  th{
    text-align: left;
    min-width: 100px;
  }
}

.table-sizes {
  tr {
    &.order-controls {
      background-color: $white;
    }
  }
}


.profile-returns{
  border-bottom: 1px solid $grey-2;
  padding-bottom: 10px;
}

.dl-horizontal dt {
  margin-bottom: 5px;
  font-size: 16px;
}

.margin-orders-mobile{
  @include breakpoint(small down) { 
    margin-top:20px;
  }

}

.order-dimension{
  font-size: 14px;
  margin-bottom: 0;
  text-transform: initial;
}

.box-wishlist {
  padding: 20px;
  border: 1px solid $grey-2;
}

.cart-button_wishlist {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.verified-icon {
  position: absolute;
  width: 20px;
  top: 42px;
  right: 20px;
}

.profile-links {
  padding-top: 40px;
  @media screen and (min-width: 40em) {
    height: 100vh;
    position: sticky;
    top: 65px;
    display: flex;
    justify-content: center;
  }
}

.inline-checkbox {
  display: inline-block;
}

.consent-title {
  @include inter-medium;
  text-transform: uppercase;
}

.profile-consent-item {
  border-bottom: 1px solid $light-grey;
  @include pt-16;
}

.profile-api-key {
  @include inter-medium;
}

.dropship-order {
  font-size: 18px;
  line-height: 24px;
  @include inter-medium;

  &.total {
    color: $gold;
  }
}

.no-order-drophip {
  @include mt-40;
  @include pt-24;
  border-top: 1px solid $grey-3;
}

.dropshipping-rewards {
  border: 1px solid $gold;
  padding: 16px;
  @include mt-24;
  @include mb-24;
  background-color: $light-grey;

  h4 {
    @include mt-8;
    @include mb-8;
  }
}

.tool-export-cit {
  font-size: 14px;
  line-height: 24px;
}